const optionsTypeDocumentWompi = [
  { value: 'CC', text: 'Cédula de Ciudadanía' },
  { value: 'CE', text: 'Cédula de Extranjería' },
  { value: 'NIT', text: 'Número de Identificación Tributaria' },
  { value: 'PP', text: 'Pasaporte' },
  { value: 'TI', text: 'Tarjeta de Identidad' },
  { value: 'DNI', text: 'Documento Nacional de Identidad' },
]

const optionsGender = [
  { value: null, text: 'Selecciona' },
  { value: 'Male', text: 'Hombre' },
  { value: 'Female', text: 'Mujer' },
  { value: 'Other', text: 'Otro' },
]

const formatJson = data => Object.values(data).flatMap(({
  company_id, name, photo, travel,
}) => {
  const travels = Array.isArray(travel) ? travel : [travel]
  return travels.map(({
    '@attributes': {
      Id,
      FechaPartida,
      FechaArribo,
      TerminalOrigenNombre,
      TerminalDestinoNombre,
      TerminalOrigenID,
      TerminalDestinoID,
      LocalidadDestinoNombre,
      LocalidadOrigenNombre,
    },
    tarifa,
  }) => ({
    idViaje: Id,
    company_id,
    empresa: name,
    photo,
    FechaPartida,
    FechaArribo,
    TerminalOrigenNombre,
    TerminalDestinoNombre,
    TerminalOrigenID,
    TerminalDestinoID,
    LocalidadDestinoNombre,
    LocalidadOrigenNombre,
    tarifa,
  }))
})

const formatJsonForm = data => Object.values(data).flatMap(({
  company_id, name, photo, transaction_fee, policy, travel,
}) => {
  const travels = Array.isArray(travel) ? travel : [travel]
  return travels.map(({
    '@attributes': {
      Id,
      FechaPartida,
      FechaArribo,
      TerminalOrigenNombre,
      TerminalDestinoNombre,
      TerminalOrigenID,
      TerminalDestinoID,
      LocalidadDestinoNombre,
      LocalidadOrigenNombre,
    },
    tarifa,
  }) => ({
    idViaje: Id,
    company_id,
    empresa: name,
    photo,
    FechaPartida,
    FechaArribo,
    TerminalOrigenNombre,
    TerminalDestinoNombre,
    tarifa,
    policy,
    transaction_fee,
    TerminalOrigenID,
    TerminalDestinoID,
    LocalidadDestinoNombre,
    LocalidadOrigenNombre,
  }))
})

export {
  optionsTypeDocumentWompi, optionsGender, formatJson, formatJsonForm,
}
