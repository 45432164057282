<template>
  <div>
    <div
      v-for="(errorArray, idx) in notifmsg"
      :key="idx"
    >
      <b-alert
        v-height-fade.appear
        show
        variant="danger"
        dismissible
        @dismiss-count-down="countDownChanged"
      >
        <div
          v-for="(allErrors, idj) in errorArray"
          :key="idj"
          class="alert-body"
        >
          {{ allErrors }}
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>

import { BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BAlert,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    notifmsg: {
      type: Object,
      default: () => [],
    },
  },
  methods: {
    countDownChanged() {
      this.notifmsg = {}
    },
  },
}

</script>
