<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h3 class="text-white">
        <b>PAGO POR TRANSFERENCIA</b>
      </h3>
    </template>
    <b-container fluid>
      <div
        v-if="optionsTypePayment"
        class="text-center"
      >
        <h4><b>Escanea el codigo QR con tu aplicación de Bancolombia o Nequi</b></h4>
        <b-img
          thumbnail
          fluid
          width="300"
          :src="setImg(optionsTypePayment[0].photo)"
        />
      </div>
      <hr>
      <div class="text-center">
        <h4><b>Sube el comprobante de pago para su validación</b></h4>
      </div>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypePaymentForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col
                  cols="12"
                >
                  <div
                    class="text-center"
                    @click="ActivateFormFile"
                  >
                    <b-img
                      thumbnail
                      fluid
                      width="200"
                      :src="getFilePath"
                    />
                    <h5><b>Presiona la foto para subir el comprobante de pago</b></h5>
                  </div>
                  <b-form-group
                    label-for="companies-photo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="ext:jpg,jpeg,png"
                      name="photo"
                    >
                      <b-form-file
                        id="fileInput"
                        ref="file-input"
                        v-model="photo"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        style="visibility: hidden;position: absolute;top: 0;left: -5000px;"
                        accept="image/jpeg, image/png, image/jpeg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          :disabled="isLoading"
          @click="saveTrasaction()"
        >
          <b-spinner
            v-if="isLoading"
            small
            type="grow"
          />
          {{ textButton }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BForm, BButton, BContainer, BFormFile, BImg, BModal, BSpinner,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
    BImg,
    BModal,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    'vue-errors': AlertErrors,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: () => false,
    },
    dataPay: {
      type: Number,
      default: () => [],
    },
    amountInCents: {
      type: String,
      default: () => '',
    },
    reference: {
      type: String,
      default: () => '',
    },
    customerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsTypePayment: null,
      isLoading: false,
      textButton: 'Enviar',
      // data
      name: null,
      description: null,
      status_id: null,
      data_of_payment: {},
      photo: null,

      optionsStatus: [],
      id: null,
      value: [],
      notifmsg: null,
      isNotifmsg: false,
      getFilePath: `${process.env.VUE_APP_URL_STORAGE}payment/upload.png`,
      // validation rules
      required,
    }
  },
  watch: {
    photo(value) {
      this.getFilePath = URL.createObjectURL(value)
      this.updatePhoto()
    },
  },
  created() {
    if (this.dataPay > 0) {
      if (localStorage.getItem('accessToken')) {
        this.getTypePaymentLogin()
      } else {
        this.getTypePayment()
      }
    }
  },
  methods: {
    setImg(value) {
      return `${process.env.VUE_APP_URL_STORAGE}${value}`
    },
    getTypePayment() {
      this.$http.get(`/v1/type_payment/web?filter[id]=${this.dataPay}`).then(res => {
        this.optionsTypePayment = res.data.data
      })
    },
    getTypePaymentLogin() {
      this.$http.get(`/v1/type-payment?filter[id]=${this.dataPay}`).then(res => {
        this.optionsTypePayment = res.data.data
      })
    },
    ActivateFormFile() {
      document.getElementById('fileInput').click()
    },
    close() {
      this.$emit('close')
    },
    updatePhoto() {
      const data = new FormData()
      data.append('photo', this.photo)
      this.$http.post(`/v1/payment/event/${this.reference}/photo`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Comprobante',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Ha sido cargado correctamente, ahora presiona el boton enviar',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    setDatatrasaction() {
      return {
        transaction: {
          id: this.reference,
          amount_in_cents: this.amountInCents,
          reference: this.reference,
          customer_email: this.customerData.email,
          currency: 'COP',
          payment_method_type: 'QR',
          payment_method: {
            type: 'QR',
            extra: {
              bin: 'QR',
              name: 'QR',
              brand: 'QR',
              exp_year: '',
              exp_month: '',
              last_four: '',
              card_holder: this.customerData.fullName,
              external_identifier: '',
              processor_response_code: '',
            },
          },
          status: 'PENDING',
          status_message: null,
          shipping_address: null,
          redirect_url: null,
          payment_source_id: null,
          payment_link_id: null,
          customer_data: {
            legal_id: this.customerData.legalId,
            full_name: this.customerData.fullName,
            phone_number: this.customerData.phoneNumber,
            legal_id_type: this.customerData.legalIdType,
          },
          billing_data: {
            legal_id_type: this.customerData.legalIdType,
            legal_id: this.customerData.legalId,
          },
        },
      }
    },
    saveTrasaction() {
      const data = this.setDatatrasaction()
      this.isLoading = true
      this.textButton = 'Creando transacción...'
      this.$http.post('/v1/payment/event', { data: data }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pago por transferencia',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Tu pago se ha enviado correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        this.textButton = 'Enviar'
        this.isLoading = false
        window.location.replace(`/payments/result/${this.reference}`)
      }).catch(() => {
        this.isLoading = false
        this.textButton = 'Enviar'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Ha ocurrido un error con tu pago',
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
